import Vue from 'vue'
import App from './App'
import router from './router'
import iView from 'iview';
import './assets/less/index.less';
import echarts from 'echarts'
import dataV from '@jiaminghi/data-view'
import img from './lib/img'
import utils from "./lib/utils";
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VScaleScreen from 'v-scale-screen'
import VueLazyload from "vue-lazyload";

Vue.prototype.$echarts = function (el) {
    return echarts.init(el, null, {renderer: 'svg'})
}
Vue.use(VueLazyload, {
    // preLoad: 1.3,
    attempt: 1,
});
Vue.prototype.$images = img
Vue.prototype.$video = Video
Vue.config.productionTip = false;
Vue.use(iView);
Vue.use(dataV);
Vue.use(utils);
Vue.use(Viewer);
Vue.use(VScaleScreen);
Vue.use(ElementUI);
Viewer.setDefaults({
    Options: {
        'inline': true, //启用lnline模式
        'button': true, //显示右上角关闭按钮
        'navbar': true, //显示缩略图导航
        'title': true, //显示当前图片的标题
        'toolbar': true, //显示工具栏
        'tooltip': true, //显示缩放百分比
        'movable': true, //显示可移动
        'zoomable': true, //图片是否可缩放
        'rotatable': true, //图片是否可旋转
        'scalable': true, //图片是否可翻转
        'transition': true, //使用css3过度
        'fullscreen': true, //播放时是否全屏
        'keyboard': true, //是否支持键盘
        'url': 'data-source' //设置大图片的url
    }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

